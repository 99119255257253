.project-conatainer{
    padding-top: 1.5rem;
    min-height: 100vh;
    padding-left: 2rem;
}
.project-type-head{
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    padding-left: 1.5rem;
}
.project-type{
    padding-top: 1.5rem;
}
.project-note{
    font-family:Arial, Helvetica, sans-serif;
    /* color:brown ; */
    font-weight: 500;
    text-align: center;
    font-size: 1rem;
    padding: 1rem;
}
.project-readmore{
    font-family:'Courier New', Courier, monospace;
    text-align: left;
    width: 97%;
    margin: auto;
    padding-top: 1.5rem;
}
.project-readmore-list{
    list-style:decimal;
    margin-top: 6px;
}
.project-readmore-list-item{
   padding-top: .5rem;
}
@media (max-width:750px) {
    .project-conatainer{
        padding-left: 0px;
    }
}