.blog-card-container{
    width: 100%;
    /* min-width: 200px;     */
    max-width: 300px;
    margin:8px;
    padding: 1px;
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.blog-card-img-container{
    width: 100%;
}
.blog-card-img{
    width: 100%;
    aspect-ratio: 2/1;
    object-fit: contain;
}
.blog-card-slide{
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-end;
    align-items:center;
    color: brown;
    /* color:dodgerblue; */
    cursor: pointer;
}
.blog-card-slide-txt{
    font-size: 1.1rem;
    margin-right: 4px ;
}
.blog-card-detail{
    display: flex;
    font-size: 1.6rem;
    padding: .5rem;
    margin-top: .5rem;
    background-color: lighty;
    overflow-x: scroll;
}
.blog-card-detail-item{
    margin-left: 8px;
}
.blog-card-content{
    padding: 1rem;
    position: relative;
}
.blog-card-head{
    font-size: 1.1rem;
    font-weight: 600;
    padding: 6px ;
}
/* .blog-card-tag{
    width: max-content;
    margin: 6px;
    margin-top: 2px;
    
} */
.blog-card-tag{
    position: absolute;
    top:4px;
    right: 4px;
    padding: 2px 6px ;
    border-radius: .3rem;
    background-color: blueviolet;
    color: white;
}
.blog-card-sub{
 font-size: 1.1rem;
 padding: 4px;

 /*  */
 max-height: 100px;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp:2;
}
.blog-card-link-container{
    padding: .5rem;
    display: flex;
    font-size: 1.1rem;
}
.blog-card-link{
    margin-left: 8px ;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.blog-card-live-link{
    color: red;
    /* width: 50%;
    border: 2px solid;
    padding: 6px; */
}
.blog-card-read-more-link{
    color: blue;
    /* width: 50%; */
    /* border: 2px solid;
    padding: 6px;
    margin-left: 4px; */
}
.blog-card-link-txt{
    margin-left: 4px;
}
@media (max-width:750px) {
    
}