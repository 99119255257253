.side-nav-conatiner{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
}
.sidenav-logo{
    padding: 1rem 0px;
    cursor: pointer;
}
.side-nav-list{
    margin: 0px 0px;
    padding-left: 0px;
    list-style: none;
    /* align-self: flex-start; */
}
.sidenav-list-item{
    padding: .5rem;
    cursor: pointer;
}
.sidenav-list-item:hover{
    border: 1px solid;
}