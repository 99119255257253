.para-container{
    line-height: 1.5rem;
    padding-left: 1rem;
    max-width: 80vw;
    margin:1rem auto;

}
.para-header{
    padding: .5rem 0px;
    padding-left: .5rem;
}
.para-head{
    font-size: 1.3rem;
    font-weight: 600;
}
.para-sub{
    font-size: 1.2rem;
    font-weight: 500;
}
.para{
    padding-left: .2rem;
    font-size: 1.1rem;

}

@media (max-width:750px) {
    .para-container{
        padding-left: .5rem;
        max-width: 100vw;
        margin-right: 0px;
        padding-right: 4px;
        margin-left:.5rem ;
        margin-right:4px;
    }
}